import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

gsap.registerPlugin(ScrollTrigger);

export default class Video {
    constructor() {
        this.init();
    }

    init() {
        const jsPlayer = document.querySelectorAll('.js-player');
        if (jsPlayer) {
            jsPlayer.forEach(p => {
                let video = new Plyr(p, {
                    muted: true,
                    autoplay: true,
                    loop: {active: true},
                    storage: {enabled: false},
                    youtube: {
                        autoplay: true,
                        background: true,
                        byline: false,
                        portrait: false,
                        title: false,
                        speed: true,
                        transparent: false
                    },
                    vimeo: {
                        autoplay: true,
                        background: true,
                        byline: false,
                        portrait: false,
                        title: false,
                        speed: true,
                        transparent: false
                    }
                });
                video.poster = p.getAttribute('data-plyr-poster');
                video.play();

                ScrollTrigger.create({
                    trigger: p.parentElement,
                    start: 'top center',
                    end: 'bottom center',
                    onEnter: () => video.play(),
                    onEnterBack: () => video.play(),
                    onLeave: () => video.pause(),
                    onLeaveBack: () => video.pause()
                });
            });
        }
    }
}