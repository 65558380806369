import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
export default class Animations {
    constructor() {
        this.init();
    }

    init() {
        // Smoother
        window.smoother = ScrollSmoother.create({
            wrapper: "#scrollContainer",
            content: "#content",
            smooth: 1.5,
            smoothTouch: 0.1,
            normalizeScroll: true,
            ignoreMobileResize: true,
            effects: true,
            preventDefault: true
        });

        window.smootherInit = () =>{
            ScrollSmoother.create({
                wrapper: "#scrollContainer",
                content: "#content",
                smooth: 1.5,
                smoothTouch: 0.1,
                normalizeScroll: true,
                ignoreMobileResize: true,
                effects: true,
                preventDefault: true
            });
        }

        let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        if (viewportWidth < 1025) {
            smoother.kill();
        }

        const templateComite = document.body.classList.contains('page-template-comite');
        const jsAnchorsNav = document.querySelector('.js-anchors-nav');
        const jsAnchorsNavWrapper = document.querySelector('.js-anchors-nav-wrapper');
        if (templateComite && jsAnchorsNav != null) {

            document.querySelectorAll('.js-anchors-nav button').forEach(anchor => {
                anchor.addEventListener('click', function (e) {
                    const sectionID = this.getAttribute("data-anchor");
                    const li = e.target.parentElement; 
                    li.blur();
                    const node = sections.querySelector(sectionID);
                    if(node){
                        jsAnchorsNav.scrollLeft = li.offsetLeft;
                        smoother.scrollTo(node, true, "top 85px");
                    }
                });
            });

            const sections = document.querySelector(".template-comite__content-items");
            const navLi = document.querySelectorAll(".js-anchors-nav > li");

            
            navLi.forEach((li) => {
                const sectionID = li.querySelector('button').dataset.anchor;
                const section = sections.querySelector(sectionID);
                if(section){
                    console.log(section);
                    ScrollTrigger.create({
                        trigger: section,
                        start: 'top-=120 top',
                        markers: false,
                        end: 'bottom-=120 top',
                        onEnter: (self) => {
                             jsAnchorsNav.scrollLeft = li.offsetLeft;
                            li.classList.add('active')
                        },
                        onEnterBack: (self) => {
                             jsAnchorsNav.scrollLeft = li.offsetLeft;
                            li.classList.add('active')
                        },
                        onLeaveBack: (self) => {
                            li.classList.remove('active')
                        },
                        onLeave: (self) => {
                            li.classList.remove('active')
                        },
                    });
                }
            });


            gsap.to(jsAnchorsNavWrapper, {
                scrollTrigger: {
                    trigger: jsAnchorsNavWrapper,
                    marker: true,
                    start: 'top top',
                    endTrigger: '.footer',
                    pin: true,
                    pinSpacing: false
                }
            });
        }

        // On Top
        if (document.querySelector('.on-top')) {
            document.querySelector('.on-top').addEventListener('click', function (e) {
                e.preventDefault();
                if (viewportWidth < 1025) {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                } else {
                    smoother.scrollTo("#site-navigations", true);
                }
            });
        }

        // Parallax
        if (document.querySelector('.section-parallax')) {
            gsap.utils.toArray(".section-parallax").forEach((section, i) => {
                gsap.matchMedia().add("(min-width: 1025px)", () => {
                    if (section.querySelector('.parallax-image')) {
                        gsap.to(section.querySelector('.parallax-image'), {
                            yPercent: 40,
                            ease: "none",
                            scrollTrigger: {
                                trigger: section.querySelector('.parallax-image'),
                                start: "top 0%",
                                scrub: true
                            }
                        });
                    }
                });
            });

            gsap.utils.toArray(".section-parallax").forEach((section, i) => {
                gsap.matchMedia().add("(min-width: 1025px)", () => {
                    if (section.querySelector('.parallax-image-down')) {
                        gsap.to(section.querySelector('.parallax-image-down'), {
                            yPercent: -15,
                            ease: "none",
                            scrollTrigger: {
                                trigger: section.querySelector('.parallax-image-down'),
                                start: "top center",
                                scrub: true
                            }
                        });
                    }
                });
            });
        }

        // Section "Block Box (homepage)"
        const blockBox = document.querySelector('.block-box__sticky-col');
        if (blockBox) {
            gsap.matchMedia().add("(min-width: 1025px)", () => {
                const q = gsap.utils.selector(blockBox);
                ScrollTrigger.create({
                    trigger: ".block-box__sticky-col",
                    start: "top 150px",
                    end: 'bottom 15%',
                    pin: q(".block-box__sticky-col"),
                    scrub: true,
                    pinSpacing: true,
                    pinnedContainer: blockBox,
                    pinType: "transform"
                });

                let fadein = gsap.utils.toArray('.anim-slide-up-item')
                fadein.forEach((item) => {
                    gsap.to(item, {
                        y: -125,
                        duration: 3,
                        scrollTrigger: {
                            trigger: blockBox,
                            scrub: true,
                            start: "top top",  // start when top of trigger target hits 50% point of viewport
                            toggleActions: "restart none none reset",
                            end: "bottom center"
                        }
                    });
                });
            });
        }

        // Section "Boxes with icons (our-promise)"
        const boxesWithIcons = document.querySelector('.template-our-promise__sticky-col');
        if (boxesWithIcons) {
            gsap.matchMedia().add("(min-width: 1025px)", () => {
                const q = gsap.utils.selector(boxesWithIcons);
                ScrollTrigger.create({
                    trigger: ".template-our-promise__sticky-col",
                    start: "top 150px",
                    end: 'bottom 15%',
                    pin: q(".template-our-promise__sticky-col"),
                    scrub: true,
                    pinSpacing: true,
                    pinnedContainer: boxesWithIcons,
                    pinType: "transform"
                });

                let fadein = gsap.utils.toArray('.anim-slide-up-item')
                fadein.forEach((item) => {

                    let tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: boxesWithIcons,
                            scrub: true,
                            start: "top top",  // start when top of trigger target hits 50% point of viewport
                            toggleActions: "restart none none reset",
                            end: "bottom center"
                        }
                    });
                    tl.to(item, {
                        y: -125,
                        duration: 3
                    });
                });
            });
        }

        // Anim label form
        if (document.querySelector('form.wpcf7-form')) {
            document.querySelectorAll("form.wpcf7-form label.anim-label").forEach((item) => {
                const wraps = document.querySelectorAll("form.wpcf7-form label.anim-label");

                item.parentElement.addEventListener('click', function () {
                    for (let i = 0; i < wraps.length; i++) {
                        wraps[i].classList.remove("active");
                    }
                    this.querySelector('label.anim-label').classList.add('active');
                    if (this.querySelector('.wpcf7-form-control-wrap input')) {
                        this.querySelector('.wpcf7-form-control-wrap input').focus();
                    }
                    if (this.querySelector('.wpcf7-form-control-wrap textarea')) {
                        this.querySelector('.wpcf7-form-control-wrap textarea').focus();
                    }

                    document.querySelectorAll("form.wpcf7-form .wpcf7-form-control-wrap input, form.wpcf7-form .wpcf7-form-control-wrap textarea").forEach((element) => {
                        if (element.value !== "") {
                            const label = element.parentElement.parentElement.querySelector('label.anim-label');
                            if (label) {
                                label.classList.add('active');
                            }
                        }
                    });
                });
            });

            document.querySelectorAll("form.wpcf7-form .wpcf7-form-control-wrap input, form.wpcf7-form .wpcf7-form-control-wrap textarea").forEach((item) => {
                item.addEventListener('keyup', function (event) {
                    if (event.key === "Tab") {
                        this.parentElement.parentElement.querySelector('label.anim-label').classList.add('active');
                    }
                });
            });

            let tagArr = document.getElementsByTagName("input");
            for (let i = 0; i < tagArr.length; i++) {
                tagArr[i].autocomplete = 'new-password';
            }

            if (document.querySelector('.wpcf7-form button[type="submit"]')) {
                document.querySelector('.wpcf7-form button[type="submit"]').addEventListener('click', function (e) {
                    const radioValue = document.querySelector('input[name="radio"]:checked').value;
                    if (radioValue === "Oui") {
                        document.querySelector('.wpcf7-form .error-radio').classList.remove('show');
                    } else {
                        e.preventDefault();
                        document.querySelector('.wpcf7-form .error-radio').classList.add('show');
                        return false;
                    }
                }, false);
            }
        }
    }
}