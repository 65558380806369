import Video from "./components/video";
import Carousel from "./components/carousel";
import Tabs from "./components/tabs";
import Map from "./components/map";
import Filters from "./components/filters";
import Mobile from "./components/mobile";
import Animations from "./components/animations";
import Viewport from "./components/viewport";
import Select from "./components/select";

const initScripts = () => {
    new Video();
    new Carousel();
    new Tabs();
    new Filters();
    new Mobile();
    new Animations();
    new Viewport();
    new Select();

    if (document.body.classList.contains('page-template-our-members')) {
        new Map();
    }
}

// initialize scripts
document.addEventListener("DOMContentLoaded", function () {
    initScripts();
});