import {gsap} from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import Selectr from "mobius1-selectr";
import "mobius1-selectr/dist/selectr.min.css";

gsap.registerPlugin(ScrollSmoother);

export default class Select {
    constructor() {
        this.init();
    }

    init() {
        if (document.getElementById('filter-region')) {
            window.selectorRegion = new Selectr('#filter-region', {
                searchable: true,
                width: 300
            });

            selectorRegion.on('selectr.open', function() {
                smoother.kill();
            });

            selectorRegion.on('selectr.close', function() {
                smootherInit();
            });
        }
        if (document.getElementById('filter-city')) {
            window.selectorCity = new Selectr('#filter-city', {
                searchable: true,
                width: 300
            });

            selectorCity.on('selectr.open', function() {
                smoother.kill();
            });

            selectorCity.on('selectr.close', function() {
                smootherInit();
            });
        }
        if (document.getElementById('filter-type')) {
            window.selectorType = new Selectr('#filter-type', {
                searchable: true,
                width: 300
            });

            selectorType.on('selectr.open', function() {
                smoother.kill();
            });

            selectorType.on('selectr.close', function() {
                smootherInit();
            });
        }
    }

}