export default class Filters {
    constructor() {
        const templateNewsEvents = document.body.classList.contains('page-template-news-events');
        if(!templateNewsEvents) return ; 
        this.myLink = null;
        this.currentURL = window.location.origin + window.location.pathname;
        this.controller = null;
        this.filterCTR = document.querySelector('.template-news-events__filters');
        this.bindMethods();
        this.getElts();
        this.getEltsAajx();
        console.log(this);
    }

    bindMethods(){
        this.ajaxFilter = this.ajaxFilter.bind(this);
        this.ajaxPagination = this.ajaxPagination.bind(this);
    }

    getElts(){
        this.allFilters = this.filterCTR.querySelectorAll('.filter');
        for (let element of this.allFilters) {
            element.addEventListener('click', this.ajaxFilter);
        }
    }

    getEltsAajx(){
        this.allPagination = document.querySelectorAll('.js-item-pagination');
        console.log(this.allPagination);
        for (let element of this.allPagination) {
            element.addEventListener('click', this.ajaxPagination);
        }
    }
  
    classToggleList(element){
        this.filterCTR.querySelector('li.active').classList.remove('active');
        element.closest('li').classList.add('active');
    }

    getFilter(){
        const elt = this.filterCTR.querySelector('li.active input');
        return elt.value;
    }

    ajaxPagination(e){
        e.preventDefault();
        var obj = {
            filter: this.getFilter(),
            paged : e.target.dataset.page
        };
        this.myLink = e.target.dataset.link +'?'+ jQuery.param(obj);
        this.changeURL(jQuery.param(obj));
        this.ajaxLoad();
    }

    ajaxFilter(e){
        e.preventDefault();
        this.classToggleList(e.target);
        var obj = {
            filter: e.target.dataset.filter,
            paged : 1
        };
        this.myLink = e.target.href +'?'+ jQuery.param(obj);
        this.changeURL(jQuery.param(obj));
        this.ajaxLoad();
    }

    ajaxLoad(){
        this.abort(this.controller);
        this.controller = new AbortController();

        smoother.scrollTo("#news", true);
        console.log(this.myLink);
        // Appel AJAX nouveaux posts
        fetch(this.myLink, {
            signal: this.controller.signal,
            method: 'GET'
        })
        .then((response) => {
            const posts = document.querySelector('.posts');
            if (posts != null) {
                document.querySelector('.posts .post').remove();
            }
            // The API call was successful!
            return response.text();
        }).then((html) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            document.getElementById('posts-wrap').innerHTML = doc.querySelector('.posts-wrap').innerHTML;
            this.getEltsAajx();
        }).catch(function (error) {
            if (error.name === 'AbortError') {
                console.log('Requête annulée');
              } else {
                console.error(error);
              }
        });
        return false;
    }

    changeURL(param){
        const newURL = this.currentURL + '?' + param;
        window.history.replaceState({}, document.title, newURL);
    }

    abort(xhrVar) {
        if (xhrVar !== null) {
          xhrVar.abort();
        }
    }
    
}