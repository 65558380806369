import { MarkerClusterer } from '@googlemaps/markerclusterer';

export default class Map {
    constructor() {
        this.init();
    }

    init() {
        const $map = document.getElementById("map");

        if ($map) {
            const $themePath = document.getElementById("map").getAttribute('data-theme-path'),
                stylesCustomization = [{
                    "featureType": "all",
                    "elementType": "all",
                    "stylers": [{ "hue": "#e7ecf0" }]
                }, {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [{ "visibility": "off" }]
                }, {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [{ "saturation": -70 }]
                }, {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [{ "visibility": "off" }]
                }, {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [{ "visibility": "simplified" }, { "saturation": -60 }]
                }];

            let map,
                locations;

            // Configuration de la MAP
            map = new google.maps.Map($map, {
                zoom: 6,
                scrollwheel: false,
                center: new google.maps.LatLng(0, 0),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: stylesCustomization,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
            });

            locations = JSON.parse($map.getAttribute('data-locations'));

            let bounds = new google.maps.LatLngBounds(),
                allMarkers = [],
                activeWindow;

            // Ajout des markers à la MAP
            for (const location in locations) {

                // Création du marker
                const latLng = new google.maps.LatLng(locations[location]['lat'], locations[location]['lng']),
                    icon = {
                        url: $themePath + "/web/dist/img/icons/pin.svg",
                        scaledSize: new google.maps.Size(27, 35),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(13, 17)
                    },
                    marker = new google.maps.Marker({
                        position: latLng,
                        map: map,
                        icon: icon,
                        customInfo: locations[location]
                    }),
                    title = locations[location]["title"],
                    city = locations[location]["city"],
                    phone = locations[location]["phone"],
                    link = locations[location]["link"],
                    type = locations[location]["type"],
                    infowindow = new google.maps.InfoWindow({
                        maxWidth: 300,
                        pixelOffset: new google.maps.Size(-130, 60)
                    });

                google.maps.event.addListener(marker, 'click', infoCallback(infowindow, marker));

                function infoCallback(content, marker) {
                    return function () {
                        var content = `<div class="tooltip-marker p-3">
                                        <span class="d-flex title fw-bold mb-4 fs-18">` + title + `</span>`;
                                        if (phone) {
                                            content += `<span className="d-flex phone mb-2">` + phone + `</span>`;
                                        }

                                    content += `<span class="d-flex flex-column metas mb-4 text-green-4">
                                        <span class="city mb-2">` + city + `</span>
                                        <span class="type">` + type + `</span></span>`;
                                    if (link) {
                                        content += `<a href="` + link + `" class="d-flex btn btn-link fw-bold fs-16" target="_blank">Visiter le site<span class="icon d-inline-flex ms-35"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.32 17.42"><path fill="#001646" d="m15.73,7.2c-.69-.32-1.3-.8-1.78-1.39-1.08-1.08-2.15-2.16-3.23-3.24-.46-.39-.64-1.02-.45-1.6.25-.74,1.05-1.13,1.79-.89.14.05.28.12.4.21.09.07.18.15.27.24,2.36,2.36,4.72,4.72,7.08,7.08.46.39.63,1.02.42,1.59-.09.23-.22.44-.39.61-2.38,2.4-4.77,4.79-7.16,7.17-.55.57-1.45.6-2.04.06-.57-.56-.59-1.47-.04-2.06.22-.25.46-.47.7-.71.87-.87,1.74-1.75,2.62-2.62.49-.61,1.1-1.1,1.81-1.44v-.05H1.63c-.45.04-.89-.12-1.21-.44-.41-.38-.54-.98-.33-1.5.2-.55.71-.93,1.29-.96.12,0,.24-.01.36,0h13.97l.02-.05"/></svg></span></a>`;
                                    }

                                    content += `</div>`;
                        if (activeWindow != null) activeWindow.close();
                        infowindow.setContent(content);
                        infowindow.open(map, marker);
                        activeWindow = infowindow;
                    };
                }

                // On ajoute ce marker au bond pour permettre un centrage par rapport aux markers
                bounds.extend(latLng);
                allMarkers.push(marker);
            }

            const position = new google.maps.LatLng(45.8665231, 0.9860612);
            this.setMarkerClusterer(map, allMarkers);
            map.setCenter(position);
        }
    }

    setMarkerClusterer(map, markers) {
        console.log(map);
        console.log(markers);
        if (this.clusterer) {
            this.clusterer.clearMarkers();
        }
        this.clusterer = new MarkerClusterer({ map, markers });
    }

}