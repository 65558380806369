export default class Viewport {
    constructor() {
        this.init();
    }

    init() {
        let viewportWidth = window.innerWidth || document.documentElement.clientWidth;

        const config = {
            root: null,
            threshold: 0.35
        };

        const observer = new IntersectionObserver(entries => {
            // Loop over the entries
            entries.forEach(entry => {
                // If the element is visible
                if (entry.isIntersecting) {
                    entry.target.classList.add('is-active');
                }
            });
        }, config);

        const animFadeIn = document.querySelectorAll('[anim-fade-in]');
        const animKeyFigures = document.querySelectorAll('[anim-key-figures]');

        if (viewportWidth > 1025) {
            animFadeIn.forEach(item => {
                observer.observe(item);
            });

            animKeyFigures.forEach(item => {
                observer.observe(item);
            });
        }
    }
}