import {tns} from 'tiny-slider';
import 'tiny-slider/dist/tiny-slider.css';

export default class Carousel {
    constructor() {
        this.init();
    }

    init() {
        if (document.querySelector('.js-block-words-slider')) {
            let jsBlockWordsSlider = tns({
                container: ".js-block-words-slider",
                axis: "vertical",
                items: 5,
                swipeAngle: false,
                speed: 400,
                center: true,
                nav: false,
                autoplayButtonOutput: false,
                autoplay: true,
                autoplayHoverPause: true,
                autoplayTimeout: 1500,
                prevButton: '.block-words-slider__slider-nav .slider-prev',
                nextButton: '.block-words-slider__slider-nav .slider-next'
            });

            jsBlockWordsSlider.events.on("indexChanged", () => {
                const info = jsBlockWordsSlider.getInfo();
                const indexCurr = info.index + 2;
                const elements = document.getElementsByClassName("vl-slide-center");
                while (elements.length > 0) {
                    elements[0].classList.remove("vl-slide-center");
                }
                info.slideItems[indexCurr].classList.add("vl-slide-center");
            });

            const jsBlockWordsSliderHorizontal = tns({
                container: ".js-block-words-slider-horizontal",
                items: 1,
                swipeAngle: false,
                speed: 400,
                center: true,
                nav: false,
                autoplayButtonOutput: false,
                autoplay: true,
                autoplayHoverPause: true,
                autoplayTimeout: 1500,
                prevButton: '.block-words-slider__slider-nav .slider-prev',
                nextButton: '.block-words-slider__slider-nav .slider-next'
            });
        }

        const jsBlockTestimonials = document.querySelector('.js-block-testimonials');
        if (jsBlockTestimonials) {
            tns({
                container: jsBlockTestimonials,
                items: 1,
                gutter: 20,
                speed: 1500,
                loop: true,
                nav: false,
                mouseDrag: true,
                prevButton: '.block-testimonials__slider-nav .slider-prev',
                nextButton: '.block-testimonials__slider-nav .slider-next'
            });
        }

        const jsSliderTimeline = document.querySelector('.js-slider-timeline');
        if (jsSliderTimeline) {
            console.log(document.querySelectorAll('.template-who-are-we__timeline-items-item').length - 1);
            const jsSliderTimelineInit = tns({
                container: jsSliderTimeline,
                items: 1,
                gutter: 30,
                startIndex: document.querySelectorAll('.template-who-are-we__timeline-items-item').length - 1,
                edgePadding: 60,
                speed: 1500,
                loop: false,
                nav: true,
                navContainer: ".customize-tools .tns-nav",
                mouseDrag: true,
                controlsContainer: ".customize-tools",
                prevButton: '.template-who-are-we__slider-nav-prev',
                nextButton: '.template-who-are-we__slider-nav-next',
                responsive: {
                    1281: {
                        edgePadding: 150
                    },
                    1441: {
                        edgePadding: 300
                    }
                }
            });

            //jsSliderTimelineInit.goTo(2);
        }
    }
}