export default class Mobile {
    constructor() {
        this.init();
    }
    init() {
        const body = document.body,
            burgerMenu = document.getElementById('burger-menu'),
            siteNavigationTopMobile = document.getElementById('site-navigation-top__mobile');

        burgerMenu.addEventListener('click', function () {
            body.classList.toggle('open-menu');
            this.classList.toggle('open');
            siteNavigationTopMobile.classList.toggle('open');
        }, false);
    }
}